import React from 'react';
import styled from '@emotion/styled';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import ComponentContainer from '../Container';

import LoadImage from '../common/LoadImage';
import H4 from '../H4';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Container = styled(ComponentContainer)`
  margin-bottom: ${({ theme }) => theme.functions?.toEm(65)};
  
  .content 
  {
border: 2px solid #F2F2F2 !important;
box-sizing: border-box !important;
border-radius: 10px !important;
padding: ${({ theme }) => theme.functions?.toEm(20)} ${({ theme }) => theme.functions?.toEm(10)} !important;
  }

  ${({ theme }) => theme.functions?.minTablet(`
margin-bottom: ${theme.functions?.toEm(130)};

    .content {
      padding: ${theme.functions?.toEm(40)} ${theme.functions?.toEm(60)};
    }
`)}

  &.eventPage {
    padding: 0;

    .content {
      border: none;
      padding: 0;
    }
  }
`;

const SliderWrapper = styled.section`
    position: relative;
    border: 2px solid #F2F2F2;
    box-sizing: border-box;
    border-radius: 10px;
    padding: ${({ theme }) => theme.functions?.toEm(20)} ${({ theme }) => theme.functions?.toEm(20)};
    max-width: ${({ theme }) => theme.container?.maxWidth};
    margin: ${props => (props.custom ? '25px auto' : '50px auto')};

    .slick-arrow {
      display: none !important;
    }

    .slick-list {
      // min-height: 167px;
      //
      // ${props => props.theme.functions?.mediaQueryMax('549px', `
      // min-height: 250px;
      // `)}
      //
      // ${props => props.theme.functions?.mediaQueryMax('401px', `
      // min-height: 350px;
      // `)}
    }
    
    .slick-track {
        ${props => props.theme.functions?.mediaQueryMax('767px', `
        flex-direction: column !important;
        width: 100% !important;
        display: flex;
      `)}
    }

    .slick-slide {
        ${props => props.theme.functions?.mediaQueryMax('767px', `
        width: 100% !important;
      `)}
    }

    ${props => (props.custom && `
      .slick-initialized .slick-slide{
        margin-right: 40px;

        a {
          display: flex;
          width: max-content;
          flex-direction: column;

          > h4
          {
            width: max-content;
            margin: 0 auto;
            text-decoration: none;
          }
        }
      }
    `)}

    .inner-slider {

      .slick-track {
        display: flex;
        flex-wrap: initial;
        flex-flow: wrap;
        ${props => (!props.custom ? `min-width: 95%; justify-content: left;` : 'width: 100% !important;')};
      }
      
      @media all and (max-width: 1100px) {
        .slick-track
        {
          justify-content: center !important;
        }

        .slick-slide {
          width: 33% !important;
        }
      }

      @media all and (max-width: 767px) {
        .slick-slide {
          width: 50% !important;
        }
        .slick-track {
            flex-direction: row !important;
        }          
      }

      //@media all and (max-width: 400px) {
      //  .slick-slide {
      //    width: 100% !important;
      //  }
      //}
    }
`;

const InnerSlider = styled.div`
  @media all and (max-width: 768px) {
    img {
      margin: 0 auto;
      max-width: 100%;
      height: auto;
    }
  }
`;

const mainSettings = {
  infinite: true,
  autoplay: true,
  speed: 600,
  autoplaySpeed: 3000,
  slidesToShow: 1,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 4,
      },
    },
  ],
};

const settings = {
  infinite: false,
  autoplay: false,
  slidesToShow: 6,
  slidesToScroll: 6,
};

const SliderComponent = ({ position, id }) => {
  const { t } = useTranslation('common');

  const custom = {
    _6185c9357e21710048dc119e: [
      {
        category: t('eventSponsors'),
        data: [
          {
            image: 'sponsor-logo/specific-event/casa_timis.png',
            url: 'https://casatimis.ro/',
          },
          {
            image: 'sponsor-logo/specific-event/mega_image.png',
            url: 'https://www.mega-image.ro/',
          },
        ],
        width: 200,
        height: 150,
      },
    ],
    _670e906b858961418b8c2fe4: [
      {
        category: t('eventSponsors'),
        data: [
          {
            image: 'sponsor-logo/specific-event/casa_timis_new.png',
            url: 'https://casatimis.ro/',
          },
          {
            image: 'sponsor-logo/specific-event/mega_image_new.png',
            url: 'https://www.mega-image.ro/',
          },
        ],
        width: 200,
        height: 200,
      },
    ],
  };

  const list = [
    {
      category: t('coordinators'),
      data: [
        {
          image: 'sponsors-coordinators/coordinator_1.png',
          url: 'https://www.mpg.com.ro/',
          name: 'MPG',
        },
        {
          image: 'sponsors-coordinators/coordinator_2.png',
          url: 'https://www.proam.ro/en/',
          name: 'PROAM',
        },
      ],
    },
    // {
    //   category: t('officialStore'),
    //   data: [
    //     {
    //       image: 'sponsors-coordinators/official_store.png',
    //       url: 'https://www.tenniswarehouse-europe.com/?vat=RO&lang=en&from=tenpar',
    //       name: 'Tennis Warehouse',
    //     },
    //   ],
    // },
    {
      category: t('officialSponsors'),
      data: [
        {
          image: 'sponsors-coordinators/sponsor_13.png',
          url: 'https://www.orbico.com/ro',
          name: 'ORBICO',
        },
      ],
    },
    {
      category: t('sponsors'),
      data: [
        {
          image: 'sponsors-coordinators/sponsor_14.png',
          url: 'https://farmaciadedetergent.ro/',
          name: 'FARMACIA DE DETERGENT',
        },
        {
          image: 'sponsors-coordinators/sponsor_15.png',
          url: 'https://www.obio.ro/',
          name: 'OBIO',
        },
        {
          image: 'sponsors-coordinators/sponsor_16.png',
          url: 'https://www.duracell.ro/',
          name: 'DURACELL',
        },
        {
          image: 'sponsors-coordinators/sponsor_17.png',
          url: 'https://www.riomare.ro/',
          name: 'RIO MARE',
        },
        {
          image: 'sponsors-coordinators/sponsor_18.png',
          url: 'https://www.pringles.com/centraleurope/en/home.html',
          name: 'Pringles',
        },
        {
          image: 'sponsors-coordinators/sponsor_20.png',
          url: 'https://organicindia.ro',
          name: 'ORGANIC INDIA',
        },
      ],
    },
    {
      category: t('sponsors'),
      data: [
        {
          image: 'sponsors-coordinators/sponsor_19.png',
          url: 'https://www.newsport.biz/',
          name: 'WILSON',
        },
        {
          image: 'sponsors-coordinators/sponsor_21.png',
          url: 'https://www.skoda.ro/',
          name: 'SKODA',
        },
        {
          image: 'sponsors-coordinators/sponsor_22.png',
          url: 'https://europeandrinks.ro/portfolio-item/izvorul-minunilor/',
          name: 'IZVORUL MINUNILOR',
        },
        {
          image: 'sponsors-coordinators/sponsor_23.png',
          url: 'https://www.maya-textil.ro/',
          name: 'MAYA TEXTIL',
        },
        {
          image: 'sponsors-coordinators/sponsor_24.png',
          url: 'https://www.farmec.ro/',
          name: 'GEROVITAL',
        },
        {
          image: 'sponsors-coordinators/sponsor_25.png',
          url: 'https://www.lindabacoperisuri.ro/',
          name: 'Lindab',
        },
      ],
    },
  ];

  const forEvent = `_${id}`;
  const sponsors = id ? custom?.[forEvent] : list;

  return (
    <Container className={position}>
      <SliderWrapper className="content" custom={id}>
        <Slider {...mainSettings}>
          {sponsors.map((sponsor, key) => (
            <div key={key}>
              <H4>{sponsor.category}</H4>
              <Slider {...settings} className="inner-slider">
                {sponsor?.data.map((data, index) => (
                  <div key={index}>
                    <InnerSlider>
                      <a href={data.url} target="_blank" rel="noreferrer">
                        {id ? <H4>{data.name}</H4> : ''}
                        <img
                          src={LoadImage(data.image)}
                          alt={data.name}
                          width={150}
                          {...(id && {
                            style: {
                              width: sponsor?.width,
                              height: sponsor?.height,
                            },
                          })}
                        />
                      </a>
                    </InnerSlider>
                  </div>
                ))}
              </Slider>
            </div>
          ))}
        </Slider>
      </SliderWrapper>
    </Container>
  );
};

export default SliderComponent;
