/** @jsxImportSource @emotion/react */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import mixpanel from '../../../mixpanel';

import {
  AsideContent,
  Banner,
  BannerImg,
  BlackSerama,
  Bold,
  Container,
  Content,
  ContentContainer,
  MapContainer,
} from './styles';

import RJTBanner from '../../../components/Campaign/RomaniaJoacaTenis/vaillantPartner';
import Badge from '../../../components/Badge';
import TopInfo from './components/Info';
import OfficialUpdates from '../components/Event/officialUpdates';
import CompetitionsWinners from '../components/Event/CompetitionsWinnersTabs/competitionsWinnersTabs';
import EventDescription from '../components/Event/eventDescription';
import Sponsors from '../../../components/Sponsors';
import RightSideComponent from '../components/Event/rightSideComponent';
import { MaintenanceBar } from '../styles';

import LoadImage from '../../../components/common/LoadImage';
import GoogleMapsComponent from '../../../components/GoogleMapsComponent';
import { fetchEventDetails } from '../reducers';
import ContentLoader from '../../../components/ContentLoader';
import { P, H4 } from '../../../components/Collection';
import DuracellAndPringles from '../../../components/Campaign/DuracellAndPringles';

const EventDetails = ({ accountInfo }) => {
  const { eventId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { data: eventInfo, status } = useSelector(state => state.events?.info);
  const { t } = useTranslation('events');

  useEffect(() => {
    if (status === 'idle' || (status === 'succeeded' && eventInfo?._id !== eventId)) {
      dispatch(fetchEventDetails(eventId));
    }
  }, [eventId, status, dispatch]);

  useEffect(() => {
    if (status === 'succeeded') {
      mixpanel.track('View Event Details', {
        sp_event_details_city: eventInfo?.club?.location?.city,
        sp_event_details_country: eventInfo?.club?.location?.country,
        sp_event_details_current_stage: eventInfo?.currentPhase?.status,
      });
    }
  }, [status]);

  if (status === 'loading') {
    return (<ContentLoader title={false} />);
  }

  if (status === 'failed' && isEmpty(eventInfo)) {
    return <Redirect to="/events" />;
  }

  const newEventEndDate = new Date(new Date().setDate(new Date(eventInfo?.createdAt).getDate() + 1)).getTime();

  const code = history.location.pathname.split('/')[1];
  const languageCode = code && code.length === 2 ? code : 'en';

  if (status === 'loading') {
    return (<ContentLoader title={false} />);
  }

  if (status === 'failed') {
    return <Redirect to="/events" />;
  }

  const ClubOrEvent = eventInfo?.club?.location ? 'club' : 'event';
  const playerPackage = eventInfo?.playerPackage?.[languageCode];

  const display = {
    campaign: accountInfo?.membership?.plan !== 'premium',
    underMaintenance: (
      !!eventInfo?.competitions?.length
      && (eventInfo?.competitions?.length === eventInfo?.competitions?.filter(e => e.status === 'MAINTENANCE').length)
    ),
    newEvent: newEventEndDate > new Date().getTime(),
    sponsors: ['6185c9357e21710048dc119e', '670e906b858961418b8c2fe4'].includes(eventInfo?._id),
    mercanti: [
      '6359b324018078004832859c',
      '6537c15cf769f3fd85dc49b7',
      '67105087858961418b8dc341',
    ].includes(eventInfo?._id),
    goldNutritionCampaign: ['62fda5ccf9531000497eaa25'].includes(eventInfo?._id),
    goldNutritionCampaign2: ['64874bf64b463f8535eeb3a1'].includes(eventInfo?._id),
    arenaTennisBG: ['641b2c63c36bd0c9f5aa3987'].includes(eventInfo?._id),
    blackSeaRama: ['63d918c511381d08b336370f'].includes(eventInfo?._id),
    ortoman: ['6554e5b1c56263a1ae4044bf'].includes(eventInfo?._id),
    RJTCampaign: [
      '661cd7a0022f4debbec95c0b', // test for dev
      '660c163327ba99b6c2f05d3c',
      '66077aa927ba99b6c2e653f0',
      '660c282127ba99b6c2f0751c',
      '6616622d27ba99b6c2ff6edf',
      '66077e9227ba99b6c2e6546a',
      '660e9b3f27ba99b6c2f3fc34',
      '6607690827ba99b6c2e65154',
      '6607490127ba99b6c2e64e1f',
      '661451df27ba99b6c2fbf380',
      '6607656f27ba99b6c2e650e5',
      '660772c627ba99b6c2e652bf',
      '6607519327ba99b6c2e64f39',
      '6607753b27ba99b6c2e652fe',
      '660781ab27ba99b6c2e654c5',
      '660c2bb927ba99b6c2f076b8',
      '66206a6e27ba99b6c2101730',
      '660f048027ba99b6c2f49738',
      '6607830527ba99b6c2e654f2',
      '66101d3727ba99b6c2f533c2',
      '660b1b5c27ba99b6c2ee70ee',
      '6610142627ba99b6c2f53162',
      '661cf45627ba99b6c207f6d4',
    ].includes(eventInfo?._id),
    DuracellAndPringles: [
      '662023f992266b21942fc99c', // test for dev
      '65c26438c56263a1aed84462',
      '65c395f3c56263a1aed9e7fd',
      '65a14966c56263a1aea1c483',
      '65c26405c56263a1aed840b1',
      '65a7173ec56263a1aeac3d5d',
      '65c39651c56263a1aed9e81e',
      '65c26479c56263a1aed8456b',
    ].includes(eventInfo?._id),
    playerPackage: eventInfo?.playerPackage?.[languageCode],
    description: !isEmpty(eventInfo?.description),
    announcements: !isEmpty(eventInfo.announcements),
    officialBall: !isEmpty(eventInfo?.officialBall),
    officialPackage: !isEmpty(playerPackage),
    location: {
      club: eventInfo?.club?.location?.coords,
      event: eventInfo?.location?.value?.coords,
    },
  };

  // event cover image
  let coverImage = null;
  const { cover = null } = eventInfo?.club?.defaultEventImages || {};
  if (cover) coverImage = `clubs/${eventInfo?.club?.clubId}/${eventInfo?.category}/${cover}`;
  if (eventInfo?.image?.cover) coverImage = `events/${eventInfo?.eventId}/${eventInfo?.image?.cover}`;

  return (
    <Container>
      <Helmet>
        <title>{t('eventDetails.seoTitle', { eventName: eventInfo?.name })}</title>
      </Helmet>
      {display.underMaintenance && (
        <MaintenanceBar>
          <img src={LoadImage('info-icon.svg')} alt="" />
          <P small bold>
            <Trans ns="events" i18nKey="underMaintenance">
              This event is Under Maintenance. Registrations are paused at the moment.
            </Trans>
          </P>
        </MaintenanceBar>
      )}

      <Banner>
        <BannerImg src={LoadImage(coverImage, true, 'cover', eventInfo?.category)} />
        {display.newEvent && (
          <Badge bg="yellow" border="#fff" textcolor="#000">
            <Trans ns="events" i18nKey="newEvent">New event</Trans>
          </Badge>
        )}
      </Banner>

      <TopInfo eventInfo={eventInfo} />

      {display.sponsors && <Sponsors position="eventPage no-margin" id={eventInfo?._id} />}

      {display.announcements && <OfficialUpdates announcements={eventInfo?.announcements} />}

      {display.blackSeaRama && (
        <a
          href="https://www.blacksearama.com/en/accommodation/boutique-hotel/"
          target="_blank"
          rel="noreferrer"
          onClick={() => mixpanel.track('[Clicked] - BlackSeaRama')}
        >
          <BlackSerama />
        </a>
      )}

      {display.arenaTennisBG && (
        <P margin="30px 0 0 0" bold background="#FFE336" borderRadius="10px" padding="20px" underlineLinks>
          <Trans ns="events" i18nKey="arenaTennis">
            This is a Sportya & Arena Tennis event.
            Registrations for Doubles draws are available online on
            <a href="https://tour.arenatennis.bg/events/black_sea_cup_23" target="_blank" rel="noreferrer">
              Arena Tennis
            </a>
            Players can register both Singles & Doubles.
          </Trans>
        </P>
      )}

      {display.ortoman && (
        <P margin="30px 0 0 0" border="4px solid #ECD64D" borderRadius="10px" padding="20px" underlineLinks>
          <Bold>Parteneriat Sportya – Ortoman</Bold>
          <br />
          Jucatorii participanti la evenimentul <strong>Platinum Brasov</strong> beneficiaza de discount la
          cazare pentru unitatile disponibile pe site-ul&nbsp;
          <a
            href="https://socialspot.ro/listings/"
            target="_blank"
            rel="noreferrer"
          >
            https://socialspot.ro/listings/
          </a>, <br /> codul de discount fiind: <strong>Ortoman7</strong>.
        </P>
      )}

      {display.mercanti && (
        <P margin="30px 0 0 0" border="4px solid #ECD64D" borderRadius="10px" padding="20px" underlineLinks>
          Romania welcomes the biggest UE Italian expat community and resonates organically with the Italian brands
          and values.
          {' '}
          <Bold>Mercanti | Business Curators of Italian Lifestyle</Bold>
          {' '}
          is joining its multi-faced professional competencies and its zest for meaningful living - into creative
          projects that brings together people, brands, businesses, milestones, from Italy and Romania.
          <br />
          <br />
          We are inviting tennis lovers into the second Mercanti Cup mixed double tournament with an Italian flavour
          and many Italian players. It is our believe that diversity adds value in every aspect of life. And it is our
          promise to boost growth and fair play in businesses and satisfaction in brand experiences.
        </P>
      )}

      {display.goldNutritionCampaign && (
        <P margin="30px 0 0 0" border="4px solid #ECD64D" borderRadius="10px" padding="20px" underlineLinks>
          Pasiunea și convingerea că niciodată calitatea nu este negociabilă au transformat o idee într-o marcă.
          GoldNutrition sărbătorește anul acesta 20 de ani de excelență, 20 de ani dedicați sportului, nutriției
          sportive, sănătații, inovației.
          <br />
          <br />
          Cele mai apreciate produse GoldNutrition, dedicate sportului, sunt testate antidoping și dețin multiple
          certificări de calitate. În funcție de obiectivul dorit, puteți opta pentru,
          {' '}
          <a
            href="https://goldnutrition.ro/proteine"
            title="proteine"
            target="_blank"
            rel="noreferrer"
          >
            proteine
          </a>
          ,
          {' '}
          <a
            href="https://goldnutrition.ro/aminoacizi"
            title="aminoacizi"
            target="_blank"
            rel="noreferrer"
          >
            aminoacizi
          </a>
          ,
          {' '}
          <a
            href="https://goldnutrition.ro/creatina"
            title="creatina"
            target="_blank"
            rel="noreferrer"
          >
            creatina
          </a>
          ,
          {' '}
          <a
            href="https://goldnutrition.ro/multivitamine"
            title="multivitamine"
            target="_blank"
            rel="noreferrer"
          >
            multivitamine
          </a>
          {' '}
          sau
          <a
            href="https://goldnutrition.ro/"
            title="suplimente
            nutritive"
            target="_blank"
            rel="noreferrer"
          >
            suplimente nutritive
          </a>
          {' '}
          pentru sănătate și wellness.
        </P>
      )}

      {display.goldNutritionCampaign2 && (
        <P margin="30px 0 0 0" bold background="#FFE336" borderRadius="10px" padding="20px" underlineLinks>
          <Trans ns="events" i18nKey="goldNutritionCampaign2">
            This is a Sportya & Arena Tennis event. Registrations for Doubles draws are available online on
            <a
              href="https://tour.arenatennis.bg/events/hemus_night_challenge_2023/"
              target="_blank"
              rel="noreferrer"
              onClick={() => mixpanel.track('[Clicked] - Arena Tennis (GN - Campaign Banner)')}
            >
              Arena Tennis
            </a>
            Players can register both Singles & Doubles.
          </Trans>
        </P>
      )}

      {/* {display.campaign && <CampaignBanner />} */}
      {display.RJTCampaign && (<RJTBanner />)}
      {display.DuracellAndPringles && (<DuracellAndPringles section="eventInfo" />)}

      {eventInfo?.competitions?.length !== 0 && (
        <CompetitionsWinners className="mb60" eventInfo={eventInfo} />)}

      <ContentContainer>
        <Content>
          {display.description
            && (
              <EventDescription
                className="mb60"
                content={eventInfo?.description}
              />
            )}

          <H4 className="mb20">
            <Trans ns="common" i18nKey="LocationGmaps">Location</Trans>
          </H4>

          <MapContainer>
            <GoogleMapsComponent coords={display.location?.[ClubOrEvent]} />
          </MapContainer>

        </Content>
        <AsideContent>
          {display.officialBall
            ? <RightSideComponent content={eventInfo?.officialBall} section="officialBall" />
            : <RightSideComponent content={{ name: t('ballToBeAnnounced') }} section="officialBall" />}

          {display.playerPackage
            && <RightSideComponent content={playerPackage} section="playerPackage" />}
        </AsideContent>
      </ContentContainer>

      <Sponsors position="eventPage no-margin" />
    </Container>
  );
};

export default EventDetails;
